import { ReactNode } from "react";
import logoSrc from "../../../front-office/components/img/clickAndShip.png";
import * as React from "react";
import { ThemeProvider } from "@mui/material";
import { VPUITheme } from "../../theme/vpui-theme";

import "./style.scss";

interface ILoginPageLayoutProps {
    children: ReactNode;
}

export const LoginPageLayout = (props: ILoginPageLayoutProps) => {
    return <ThemeProvider theme={VPUITheme}>
        <div className="loginPage">
            <div className="logoContainer">
                <img className="logo" src={logoSrc} />
            </div>
            <div className="loginForm">
                {props.children}
            </div>
        </div>
    </ThemeProvider>;
};
