import { qzManager } from "../../qz/qz-manager";
import { context } from "../../../context/context";
import ZplSample from "./samples/sample.zpl";
import PdfSample from "./samples/sample.pdf";

export class TestPagePrinter {
    public async print() {
        const urlToCall = this.testFile;
        const headers: { [key: string]: string } = {};
        headers["Content-Type"] = "text/plain";
        const response = await fetch(urlToCall, { method: "GET", headers });
        const file = await response.blob();

        if (file) {
            await qzManager.qzPrintOrDownloadFile(file as File, context.settings.printerType);
        }
    }

    private get testFile() {
        switch (context.settings.printerType) {
            case "Zpl": {
                return ZplSample;
            }
            case "Pdf": {
                return PdfSample;
            }
            default:
                throw new Error(`Extension ${context.settings.printerType} is not implemented`);
        }
    }
}
