import { LocaleType } from "../../../constants/constants";
import React, { useState } from "react";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { context } from "../../../context/context";

import FlagIconGB from "../../../common/component/img/flag_gb.svg";
import FlagIconFR from "../../../common/component/img/flag_fr.svg";
import FlagIconES from "../../../common/component/img/flag_es.svg";
import FlagIconIT from "../../../common/component/img/flag_it.svg";

import "./style.scss";

interface ILanguageSelectorProps {
    locale: LocaleType;
}

interface ILanguageSelectorMenuProps {
    anchor: HTMLElement;
    onClose: () => void;
}

interface ILanguageSelectorItemProps {
    locale: LocaleType;
    onClick: (locale: LocaleType) => void;
}

export const LanguageSelector = (props: ILanguageSelectorProps) => {
    const [languageMenuAnchor, setLanguageMenuAnchor] = useState<HTMLElement | null>(null);

    return <>
        <Button variant={"outlined"}
                data-testid={"locale-select"}
                className={"language-selector__button"}
                color={languageMenuAnchor === null ? "inherit" : "secondary"}
                onClick={event => setLanguageMenuAnchor(event.currentTarget)}
                startIcon={<img className={"language-selector__flag"}
                                data-testid={`locale-${props.locale}-selected`}
                                src={flagFromLocale(props.locale)}/>}
                endIcon={<KeyboardArrowDown />}
                size={"large"}>
            {labelShortFromLocale(props.locale)}
        </Button>
        {languageMenuAnchor && <LanguageSelectorMenu anchor={languageMenuAnchor}
                                                     onClose={() => setLanguageMenuAnchor(null)} />}
    </>;
};

const LanguageSelectorMenu = (props: ILanguageSelectorMenuProps) => {
    const handleItemClick = async (locale: LocaleType) => {
        await context.updateLocale(locale.toString());
        props.onClose();
    };

    return <Menu open={Boolean(props.anchor)}
                 className={"language-menu"}
                 data-testid={"language-menu"}
                 elevation={0}
                 anchorEl={props.anchor}
                 anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                 onClose={props.onClose}>
        <LanguageSelectorMenuItem key={"en-GB"} locale={"en-GB"} onClick={locale => handleItemClick(locale)} />
        <LanguageSelectorMenuItem key={"fr-FR"} locale={"fr-FR"} onClick={locale => handleItemClick(locale)} />
        <LanguageSelectorMenuItem key={"es-ES"} locale={"es-ES"} onClick={locale => handleItemClick(locale)} />
        <LanguageSelectorMenuItem key={"it-IT"} locale={"it-IT"} onClick={locale => handleItemClick(locale)} />
    </Menu>;
};

const LanguageSelectorMenuItem = (props: ILanguageSelectorItemProps) => {
    return <MenuItem onClick={() => props.onClick(props.locale)}
                     data-testid={`locale-${props.locale}`}
                     className={"language-menu__item"}>
        <ListItemIcon>
            <img className={"language-menu__flag"} src={flagFromLocale(props.locale)} />
        </ListItemIcon>
        <ListItemText className={"language-menu__label"} primary={labelFromLocale(props.locale)} />
    </MenuItem>;
};

const flagFromLocale = (locale: LocaleType) => {
    switch (locale) {
        case "fr-FR":
            return FlagIconFR;
        case "en-GB":
            return FlagIconGB;
        case "es-ES":
            return FlagIconES;
        case "it-IT":
            return FlagIconIT;
        default:
            return "";
    }
};

const labelFromLocale = (locale: LocaleType) => {
    switch (locale) {
        case "fr-FR":
            return "Français";
        case "en-GB":
            return "English";
        case "es-ES":
            return "Español";
        case "it-IT":
            return "Italiano";
        default:
            return "English";
    }
};

const labelShortFromLocale = (locale: LocaleType) => {
    switch (locale) {
        case "fr-FR":
            return "FR";
        case "en-GB":
            return "EN";
        case "es-ES":
            return "ES";
        case "it-IT":
            return "IT";
        default:
            return "EN";
    }
};
