import "./style.scss";

import * as React from "react";
import QZLogoIcon from "../img/Qz_service_running.jpg";

import { Button, Grid, Card, Typography } from "@mui/material";
import { inject, observer } from "mobx-react";

import { IHistoryProps } from "../../../common/navigation/ihistory-props";
import { t } from "i18next";

@inject("routing")
@observer
export class Help extends React.Component<IHistoryProps, {}> {

    public render() {
        return <div className="help">
            <Typography variant="h6" className="title">{t("help.qz.title")}</Typography>
            <Card className="instructions">
                <Typography variant="h6" className="title2">{t("help.qz.requirements")}</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        {this.renderPrerequisiteQz()}
                        {this.renderPrerequisiteBrowser()}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {this.renderPrerequisiteOS()}
                        {this.renderPrerequisiteHarware()}
                    </Grid>
                </Grid>

                {this.renderInstructionsJava()}
                {this.renderInstructionsQzInstall()}
                {this.renderInstructionsQzLaunch()}
                {this.renderInstructionsQzDropNet()}
            </Card>
        </div>;
    }

    private renderPrerequisiteQz() {
        return <div className="prerequisite">
            <Grid container spacing={2} justifyContent="flex-start" className="header">
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{t("help.qz.qzProduct")}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{t("help.qz.mininumVersion")}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} className="row">
                <Grid item xs={12} sm={6}><Typography variant="body1">QZ Tray 2.1</Typography></Grid>
                <Grid item xs={12} sm={6}><Typography variant="body1">Java 8</Typography></Grid>
            </Grid>
        </div>;
    }

    private renderPrerequisiteBrowser() {
        return <div className="prerequisite">
            <Grid container spacing={2} justifyContent="flex-start" className="header">
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{t("help.qz.webBrowsers")}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{t("help.qz.mininumVersion")}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} className="row">
                <Grid item xs={12} sm={6}><Typography variant="body1">Safari</Typography></Grid>
                <Grid item xs={12} sm={6}><Typography variant="body1">Safari 6.0.3</Typography></Grid>
            </Grid>
            <Grid container spacing={2} className="row">
                <Grid item xs={12} sm={6}><Typography variant="body1">Firefox</Typography></Grid>
                <Grid item xs={12} sm={6}><Typography variant="body1">Firefox 31</Typography></Grid>
            </Grid>
            <Grid container spacing={2} className="row">
                <Grid item xs={12} sm={6}><Typography variant="body1">Chrome</Typography></Grid>
                <Grid item xs={12} sm={6}><Typography variant="body1">Chrome 31</Typography></Grid>
            </Grid>
            <Grid container spacing={2} className="row">
                <Grid item xs={12} sm={6}><Typography variant="body1">Internet Explorer</Typography></Grid>
                <Grid item xs={12} sm={6}><Typography variant="body1">IE 10</Typography></Grid>
            </Grid>
            <Grid container spacing={2} className="row">
                <Grid item xs={12} sm={6}><Typography variant="body1">Microsoft Edge</Typography></Grid>
                <Grid item xs={12} sm={6}><Typography variant="body1">20.x</Typography></Grid>
            </Grid>
        </div>;
    }

    private renderPrerequisiteOS() {
        return <div className="prerequisite">
            <Grid container spacing={2} justifyContent="flex-start" className="header">
                <Grid item xs={12} sm={6}><Typography variant="body1">{t("help.qz.os")}</Typography></Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{t("help.qz.mininumVersion")}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} className="row">
                <Grid item xs={12} sm={6}><Typography variant="body1">Apple OS X</Typography></Grid>
                <Grid item xs={12} sm={6}><Typography variant="body1">OS X 10.7 Lion</Typography></Grid>
            </Grid>
            <Grid container spacing={2} className="row">
                <Grid item xs={12} sm={6}><Typography variant="body1">Microsoft Windows</Typography></Grid>
                <Grid item xs={12} sm={6}><Typography variant="body1">Windows XP</Typography></Grid>
            </Grid>
            <Grid container spacing={2} className="row">
                <Grid item xs={12} sm={6}><Typography variant="body1">Linux</Typography></Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Ubuntu 12.04 Precise</Typography>
                </Grid>
            </Grid>
        </div>;
    }

    private renderPrerequisiteHarware() {
        return <div className="prerequisite">
            <Grid container spacing={2} justifyContent="flex-start" className="header">
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{t("help.qz.hardware")}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{t("help.qz.mininumRequired")}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} className="row">
                <Grid item xs={12} sm={6}><Typography variant="body1">RAM</Typography></Grid>
                <Grid item xs={12} sm={6}><Typography variant="body1">512 Mo</Typography></Grid>
            </Grid>
            <Grid container spacing={2} className="row">
                <Grid item xs={12} sm={6}>
                    <Typography variant="body1">{t("help.qz.freeSpaceDisk")}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}><Typography variant="body1">6 Go</Typography></Grid>
            </Grid>
        </div>;
    }

    private renderInstructionsJava() {
        return <div className="instructions">
            <Typography variant="h6" className="title2">{t("help.qz.javaTitle")}</Typography>
            <Typography variant="body1" className="text">
                {t("help.qz.javaDownload")}
                <Button className=""
                    href={this.urlToDownloadJava()}>
                    {t("help.qz.linkDownload")}
                </Button>
            </Typography>
            <Typography variant="body1" className="text">
                {t("help.qz.javaInstall")}
            </Typography>
        </div>;
    }

    private renderInstructionsQzInstall() {
        return <div className="instructions">
            <Typography variant="h6" className="title2">{t("help.qz.qzTitle")}</Typography>
            <Typography variant="body1" className="text">
                {t("help.qz.qzDownload")}
                <Button className=""
                    href={this.urlToDownloadQz()}>
                    {t("help.qz.linkDownload")}
                </Button>
            </Typography>
            <Typography variant="body1" className="text">
                {t("help.qz.qzInstall")}
            </Typography>
        </div>;
    }

    private renderInstructionsQzLaunch() {
        return <div className="instructions">
            <Typography variant="h6" className="title2">{t("help.qz.qzRunTitle")}</Typography>
            <Typography variant="body1" className="text">
                {t("help.qz.qzRun")}
            </Typography>
            <Typography variant="body1" className="text">
                <img className="logo" src={QZLogoIcon} />
            </Typography>
        </div>;
    }

    private renderInstructionsQzDropNet() {
        return <div className="instructions">
            <Typography variant="h6" className="title2">{t("help.qz.qzFrontTitle")}</Typography>
            <Typography variant="body1" className="text">
                {t("help.qz.qzFrontUse")}
            </Typography>
        </div>;
    }

    private urlToDownloadJava() {
        return "https://javadl.oracle.com/webapps/download/AutoDL?BundleId=227521_e758a0de34e24606bca991d704f6dcbf";
    }

    private urlToDownloadQz() {
        return "https://github.com/qzind/tray/releases/download/v2.1.4/qz-tray-2.1.4.exe";
    }
}
