import { LabelFormatType, ParcelBulkCreationMode } from "../../constants/constants";
import { PalletStatusType, ParcelStatusType } from "../constants/constants";
import moment from "moment";

export interface IUrlBuilder {
    setOperation(operationCode: string): IUrlBuilder;
    setBatchId(batchId: number): IUrlBuilder;
    setStockout(): IUrlBuilder;
    setShipment(): IUrlBuilder;
    setStockoutSimulation(): IUrlBuilder;
    setUnstockout(): IUrlBuilder;
    setUnstockoutSimulation(): IUrlBuilder;
    setDeliveryOrderId(deliveryOrderId: number): IUrlBuilder;
    setSupplierReference(supplierReference: string): IUrlBuilder;
    setProductId(productId: number): IUrlBuilder;
    setParcelId(parcelId: number): IUrlBuilder;
    setParcelLocator(locator: string): IUrlBuilder;
    setLabel(): IUrlBuilder;
    setLabelType(labelType: LabelFormatType): IUrlBuilder;
    setPreparationOrder(): IUrlBuilder;
    setParcelBulkCreationMode(mode: ParcelBulkCreationMode): IUrlBuilder;
    setMarkAsReadyToBeShipped(): IUrlBuilder;
    setShip(): IUrlBuilder;
    setParcelStatus(parcelStatus: ParcelStatusType): IUrlBuilder;
    setEan(ean13: string): IUrlBuilder;
    setPickAndPack(labelType: string): IUrlBuilder;
    setDocumentId(documentId: string): IUrlBuilder;
    setFile(): IUrlBuilder;
    setJobStatus(): IUrlBuilder;
    setTrackingAnnouncement(): IUrlBuilder;
    setConfirmCancellation(): IUrlBuilder;
    setCarrierGroupList(): IUrlBuilder;
    setPalletCode(palletCode: string): IUrlBuilder;
    setAdd(): IUrlBuilder;
    setReassign(): IUrlBuilder;
    setWarehouse(warehouse: string): IUrlBuilder;
    setPalletStatus(status: PalletStatusType): IUrlBuilder;
    setPalletId(palletId: string): IUrlBuilder;
    setPallet(): IUrlBuilder;
    setParcelRemove(): IUrlBuilder;
    setParcelRemoveByLocator(locator: string): IUrlBuilder;
    setClose(): IUrlBuilder;
    setCancel(): IUrlBuilder;
    setReopen(): IUrlBuilder;
    setMultiplePalletsShipping(): IUrlBuilder;
    setShippingDependencies(): IUrlBuilder;
    setDeliverySlip(): IUrlBuilder;
    setCreate(): IUrlBuilder;
    setProductsList(): IUrlBuilder;
    setList(): IUrlBuilder;
    setCounters(): IUrlBuilder;
    setShippingInformation(): IUrlBuilder;
    search(query: string): IUrlBuilder;
    setPickupSlots(): IUrlBuilder;
    setArrivalDate(arrivalDate: moment.Moment): IUrlBuilder;

    build(): string;
}

/**
 * Helper class to build an url
 */
export class UrlBuilder implements IUrlBuilder {

    private url: string;

    /**
     * Initialize a new instance of url builder.
     */
    constructor(rootUrl?: string) {
        this.url = rootUrl ?? "";
    }

    /**
     * Add operation code in url
     * @param operationCode Operation code
     */
    public setOperation(operationCode: string): IUrlBuilder {
        this.url = `${this.url}/${operationCode}`;
        return this;
    }

    /**
     * Add bacth identifier in url
     * @param batchId Batch identifier
     */
    public setBatchId(batchId: number): IUrlBuilder {
        this.url = `${this.url}/batch/${batchId}`;
        return this;
    }

    public setStockout(): IUrlBuilder {
        this.url = `${this.url}/stockout`;
        return this;
    }

    public setShipment(): IUrlBuilder {
        this.url = `${this.url}/shipment`;
        return this;
    }
    public setStockoutSimulation(): IUrlBuilder {
        this.url = `${this.url}/stockoutsimulation`;
        return this;
    }

    public setUnstockout(): IUrlBuilder {
        this.url = `${this.url}/unstockout`;
        return this;
    }

    public setUnstockoutSimulation(): IUrlBuilder {
        this.url = `${this.url}/unstockoutsimulation`;
        return this;
    }

    public setSupplierReference(supplierReference: string): IUrlBuilder {
        this.url = `${this.url}/product/${supplierReference}`;
        return this;
    }

    public setProductId(productId: number): IUrlBuilder {
        this.url = `${this.url}/product/${productId}`;
        return this;
    }

    /**
     * Add delivery order identifier in url
     * @param deliveryOrderId Delivery order identifier
     */
    public setDeliveryOrderId(deliveryOrderId: number): IUrlBuilder {
        this.url = `${this.url}/deliveryorder/${deliveryOrderId}`;
        return this;
    }

    /**
     * Add parcel identifier in url
     * @param parcelId Delivery order identifier
     */
    public setParcelId(parcelId: number): IUrlBuilder {
        this.url = `${this.url}/parcel/${parcelId}`;
        return this;
    }

    /**
     * Add parcel locator in url
     * @param parcelId Delivery order identifier
     */
    public setParcelLocator(locator: string): IUrlBuilder {
        this.url = `${this.url}/parcel/${locator}`;
        return this;
    }

    public setLabel(): IUrlBuilder {
        this.url = `${this.url}/label`;
        return this;
    }

    /**
     * Add label type in url
     * @param labelType Label file type
     */
    public setLabelType(labelType: string): IUrlBuilder {
        this.url = `${this.url}/label?labelType=${labelType}`;
        return this;
    }

    public setPreparationOrder(): IUrlBuilder {
        this.url = `${this.url}/preparationorder`;
        return this;
    }

    public setParcelBulkCreationMode(mode: string): IUrlBuilder {
        this.url = `${this.url}?mode=${mode}`;
        return this;
    }

    public setMarkAsReadyToBeShipped(): IUrlBuilder {
        this.url = `${this.url}/readytoship`;
        return this;
    }

    public setShip(): IUrlBuilder {
        this.url = `${this.url}/ship`;
        return this;
    }

    public setParcelStatus(parcelStatus: ParcelStatusType) {
        this.url = `${this.url}/status/${parcelStatus}`;
        return this;
    }

    public setEan(ean13: string): IUrlBuilder {
        this.url = `${this.url}/ean/${ean13}`;
        return this;
    }

    public setPickAndPack(labelType: string): IUrlBuilder {
        this.url = `${this.url}/pickandpack?labelType=${labelType}`;
        return this;
    }

    public setDocumentId(documentId: string): IUrlBuilder {
        this.url = `${this.url}/document/${documentId}`;
        return this;
    }

    public setFile(): IUrlBuilder {
        this.url = `${this.url}/file`;
        return this;
    }

    public setJobStatus(): IUrlBuilder {
        this.url = `${this.url}/job-status`;
        return this;
    }

    public setTrackingAnnouncement(): IUrlBuilder {
        this.url = `${this.url}/trackingdata`;

        return this;
    }

    public setConfirmCancellation(): IUrlBuilder {
        this.url = `${this.url}/cancellation-request/confirm`;

        return this;
    }

    public setCarrierGroupList(): IUrlBuilder {
        this.url = `${this.url}/carrier/group/list`;

        return this;
    }

    public setWarehouse(warehouse: string): IUrlBuilder {
        this.url = `${this.url}/warehouse/${warehouse}`;

        return this;
    }

    public setPallet(): IUrlBuilder {
        this.url = `${this.url}/pallet`;

        return this;
    }

    public setPalletId(palletId: string): IUrlBuilder {
        this.url = `${this.url}/pallet/${palletId}`;

        return this;
    }

    public setPalletCode(palletCode: string): IUrlBuilder {
        this.url = `${this.url}/pallet/code/${palletCode}`;

        return this;
    }

    public setParcelRemove(): IUrlBuilder {
        this.url = `${this.url}/parcel/remove`;

        return this;
    }

    public setParcelRemoveByLocator(locator: string): IUrlBuilder {
        this.url = `${this.url}/parcel/${locator}/remove`;

        return this;
    }

    public setAdd(): IUrlBuilder {
        this.url = `${this.url}/add`;

        return this;
    }

    public setReassign(): IUrlBuilder {
        this.url = `${this.url}/reassign`;

        return this;
    }

    public setPalletStatus(status: PalletStatusType): IUrlBuilder {
        this.url = `${this.url}/status/${status}`;

        return this;
    }

    public setClose(): IUrlBuilder {
        this.url = `${this.url}/close`;

        return this;
    }

    public setCancel(): IUrlBuilder {
        this.url = `${this.url}/cancel`;

        return this;
    }

    public setReopen(): IUrlBuilder {
        this.url = `${this.url}/reopen`;

        return this;
    }

    public setMultiplePalletsShipping(): IUrlBuilder {
        this.url = `${this.url}/pallet/shipMultiple`;

        return this;
    }

    public setShippingDependencies(): IUrlBuilder {
        this.url = `${this.url}/shippingDependencies`;

        return this;
    }

    public setDeliverySlip(): IUrlBuilder {
        this.url = `${this.url}/deliverySlip`;

        return this;
    }

    public setCreate(): IUrlBuilder {
        this.url = `${this.url}/create`;

        return this;
    }

    public setProductsList(): IUrlBuilder {
        this.url = `${this.url}/operation-product/list`;

        return this;
    }

    public setList(): IUrlBuilder {
        this.url = `${this.url}/list`;

        return this;
    }

    public setCounters(): IUrlBuilder {
        this.url = `${this.url}/counters`;

        return this;
    }

    public setShippingInformation(): IUrlBuilder {
        this.url = `${this.url}/shippingInformation`;

        return this;
    }

    public search(query: string): IUrlBuilder {
        this.url = `${this.url}/search/${encodeURI(query)}`;

        return this;
    }

    public setPickupSlots(): IUrlBuilder {
        this.url = `${this.url}/pickup-slots`;

        return this;
    }

    public setArrivalDate(arrivalDate: moment.Moment): IUrlBuilder {
        this.url = `${this.url}?arrivalDate=${arrivalDate.format("YYYY-MM-DD")}`;

        return this;
    }

    /**
     * Build url.
     */
    public build(): string {
        return this.url;
    }
}
