import { DeliveryOrder } from "../../../../model/delivery-order";
import { BulkParcelUploadImportLine } from "../../../../model/bulk-parcel-upload-import-line";
import { uniq, differenceWith } from "lodash";
import { t } from "i18next";
import { DeliveryOrderDetail } from "../../../../model/delivery-order-detail";
import { UploadedDeliveryOrderBuilder } from "./uploaded-delivery-order-builder";
import { UploadedDeliveryOrder } from "./uploaded-delivery-order";
import { UploadedParcel } from "./uploaded-parcel";
import { UploadedStockout } from "./uploaded-stockout";
import { context } from "../../../../../context/context";
import { Permission } from "../../../../../context/permission";
import { Parcel } from "../../../../model/parcel";

export class BulkParcelUploadDeliveryOrderUpdateProcessor {

    private _uploadedDeliveryOrderBuilder = new UploadedDeliveryOrderBuilder();

    public process(deliveryOrder: DeliveryOrder, importDataLines: BulkParcelUploadImportLine[]): DeliveryOrder {
        const unparcelledProducts = this.unparcelledProductsForOrder(deliveryOrder, importDataLines);
        if (unparcelledProducts.length > 0) {
            throw new Error(t("bulkParcelUpload.error.orderNotFulfilled", {
                deliveryOrderId: deliveryOrder.deliveryOrderId,
                missingProductsName: unparcelledProducts.map(p => p.name).join(","),
            }));
        }

        return this.updateDeliveryOrderWithUploadOrder(deliveryOrder, this._uploadedDeliveryOrderBuilder.createFromImportLines(importDataLines));
    }

    private unparcelledProductsForOrder(deliveryOrder: DeliveryOrder, importDataLines: BulkParcelUploadImportLine[]): DeliveryOrderDetail[] {
        return differenceWith(deliveryOrder.details, uniq(importDataLines.map(product => product.productId)) || [],
            (product: DeliveryOrderDetail, productId: number) => product.productId === productId);
    }

    private updateDeliveryOrderWithUploadOrder(deliveryOrder: DeliveryOrder, uploadedDeliveryOrder: UploadedDeliveryOrder): DeliveryOrder {
        uploadedDeliveryOrder.parcelsNotReuploaded(deliveryOrder.parcels)
            .forEach(parcel => deliveryOrder.cancelParcel(parcel.parcelId));

        uploadedDeliveryOrder.parcels
            .forEach(uploadedParcel => {
                const parcel = !uploadedParcel.isSubmitted ?
                    this.createParcel(uploadedParcel, deliveryOrder) : this.updateParcel(uploadedParcel, deliveryOrder);

                if (context.hasPermission(Permission.FeatureBlueBrand)) {
                    this.validateAndAnnounceParcelTracker(deliveryOrder, parcel, uploadedParcel);
                }
            });

        uploadedDeliveryOrder.stockouts
            .forEach(stockout => this.submitStockoutForDetail(deliveryOrder, stockout));

        return deliveryOrder;
    }

    private createParcel(uploadedParcel: UploadedParcel, deliveryOrder: DeliveryOrder): Parcel {
        return deliveryOrder.createParcel(uploadedParcel.toParcelDetails(deliveryOrder));
    }

    private updateParcel(uploadedParcel: UploadedParcel, deliveryOrder: DeliveryOrder) {
        const originalParcel = deliveryOrder.parcels.find(p => p.parcelId === uploadedParcel.id);

        if (originalParcel === undefined) {
            throw new Error(t("error.deliveryOrder.parcelNotInOrder", {
                parcelId: uploadedParcel.id,
                deliveryOrder: deliveryOrder.deliveryOrderId,
            }));
        }

        if (uploadedParcel.isUploadedContentUpdated(originalParcel.details)) {
            deliveryOrder.cancelParcel(originalParcel.parcelId);

            return this.createParcel(uploadedParcel, deliveryOrder);
        }

        return originalParcel;
    }

    private validateAndAnnounceParcelTracker(deliveryOrder: DeliveryOrder, parcel: Parcel, uploadedParcel: UploadedParcel) {
        if (!uploadedParcel.isParcelTrackerUpdatedForParcel(parcel) && !uploadedParcel.isCarrierUpdatedForParcel(parcel)) {
            return;
        }

        if (parcel.status !== "New" && parcel.status !== "Labeled" && parcel.status !== "ReadyToShip") {
            throw new Error(t("bulkParcelUpload.error.parcelInvalidStatusForTrackerAnnouncement", {
                parcelId: parcel.parcelId,
                status: parcel.status,
            }));
        }

        if (uploadedParcel.isParcelTrackerOrCarrierRemovedFromParcel(parcel)) {
            throw new Error(t("bulkParcelUpload.error.removeParcelTrackerViolation", {
                parcelId: parcel.parcelId,
            }));
        }

        if (uploadedParcel.isCarrierUpdatedForParcel(parcel) && uploadedParcel.trackerOrEmpty === "") {
            throw new Error(t("bulkParcelUpload.error.carrierAlteredWithoutParcelTrackerViolation", {
                parcelId: parcel.parcelId,
            }));
        }

        if (!uploadedParcel.isParcelTrackerValid(deliveryOrder, parcel)) {
            throw new Error(t("bulkParcelUpload.error.bluebrandInvalidParcelTracker", {
                deliveryOrderId: deliveryOrder.id,
            }));
        }

        parcel.updateParcelTrackingData(uploadedParcel.carrierNameOrEmpty, uploadedParcel.trackerOrEmpty, deliveryOrder);
    }

    private submitStockoutForDetail(deliveryOrder: DeliveryOrder, stockout: UploadedStockout) {
        const deliveryOrderDetail = deliveryOrder.getDetailByProductId(stockout.productId);

        if (deliveryOrderDetail.quantityStockout > stockout.stockoutQuantity) {
            throw new Error(t("bulkParcelUpload.error.removeStockoutError", {
                productName: deliveryOrderDetail.name,
            }));
        }

        if (deliveryOrderDetail.quantityStockout < stockout.stockoutQuantity) {
            deliveryOrder.stockoutProduct(stockout.productId, stockout.stockoutQuantity - deliveryOrderDetail.quantityStockout);
        }
    }
}
