import React, { useEffect } from "react";
import { usePalletShippingContext, usePalletShippingContextDispatcher } from "../pallet-shipping-context";
import { FormControl, FormLabel, TextField } from "@mui/material";
import { t } from "i18next";

interface IPalletShippingInformationManualInputProps {
    isTruckIdentifierRequired: boolean;
}

export const PalletShippingInformationManualInput = (props: IPalletShippingInformationManualInputProps) => {
    const palletShippingContextDispatcher = usePalletShippingContextDispatcher();
    const {
        comment,
        shippingInformation: {truckIdentifier, containerSealNumber, pickingDate},
    } = usePalletShippingContext();

    useEffect(() => {
        palletShippingContextDispatcher({action: "initialize", isTruckIdentifierRequired: props.isTruckIdentifierRequired});
    }, []);

    return <>
        <div className={"pallet-shipping-form__container"}>
            <FormControl variant={"outlined"}
                         fullWidth>
                <FormLabel htmlFor={"ship-picking-date"}>
                    {t("components.pallet.shipping.pickingDate")}
                </FormLabel>
                <TextField id="ship-picking-date"
                           variant={"outlined"}
                           data-testid={"ship-picking-date"}
                           value={pickingDate.format("Y-MM-DD")}
                           disabled={true}/>
            </FormControl>
            <FormControl variant={"outlined"}
                         fullWidth>
                <FormLabel htmlFor={"ship-truck-id"}>
                    {t("components.pallet.shipping.truckId")}
                </FormLabel>
                <TextField id={"ship-truck-id"}
                           variant={"outlined"}
                           data-testid={"ship-truck-id"}
                           onChange={event => palletShippingContextDispatcher({
                               action: "setTruckId",
                               truckId: event.target.value,
                           })}
                           value={truckIdentifier}/>
            </FormControl>
            <FormControl variant={"outlined"}
                         fullWidth>
                <FormLabel htmlFor={"ship-truck-id"}>
                    {t("components.pallet.shipping.containerSealNumber")}
                </FormLabel>
                <TextField id={"ship-truck-id"}
                           variant={"outlined"}
                           data-testid={"ship-truck-id"}
                           onChange={event => palletShippingContextDispatcher({
                               action: "setSealNumber",
                               sealNumber: event.target.value,
                           })}
                           value={containerSealNumber}/>
            </FormControl>
        </div>
        <div className={"pallet-shipping-form__comment"}>
            <FormControl variant={"outlined"} fullWidth>
                <FormLabel htmlFor={"closing-comment"}>
                    {t("components.pallet.shipping.comment")}
                </FormLabel>
                <TextField id={"shipping-comment"}
                           variant={"outlined"}
                           data-testid={"shipping-comment"}
                           multiline
                           rows={2}
                           maxRows={4}
                           inputProps={{maxLength: 255}}
                           onChange={event => palletShippingContextDispatcher({
                               action: "setComment",
                               comment: event.target.value,
                           })}
                           value={comment}/>
            </FormControl>
        </div>
    </>;
};
