import React from "react";
import { usePalletShippingContext, usePalletShippingContextDispatcher } from "../pallet-shipping-context";
import { PalletShippingInformationSelectItem } from "./pallet-shipping-information-select-item";
import { pickupSlotsService } from "../../../../services/pickup-slots-service";
import { FormControl, FormLabel, TextField } from "@mui/material";
import { t } from "i18next";
import { PalletShippingInformationSelect, ShippingInformationType } from "./pallet-shipping-information-select";
import { useWarehouseContext } from "../../warehouse-context/warehouse-context-provider";

export const PalletShippingInformationPickupSlotInput = () => {
    const palletShippingContextDispatcher = usePalletShippingContextDispatcher();
    const warehouseContext = useWarehouseContext();

    const {
        shippingInformation: {containerSealNumber},
    } = usePalletShippingContext();

    const loadPickupSlots = async () => {
        const response = await pickupSlotsService.getPickupSlots(warehouseContext.code as string);

        return response.map(ps => new PalletShippingInformationSelectItem(
            ps.id,
            ps.estimatedArrivalDate,
            ps.truckLicensePlate,
            "",
            ps.format(),
        ));
    };

    return <>
        <div className={"pallet-shipping-form__container"}>
            <FormControl variant={"outlined"}
                         fullWidth>
                <FormLabel htmlFor={"ship-truck-id"}>
                    {t("components.pallet.shipping.containerSealNumber")}
                </FormLabel>
                <TextField id={"ship-truck-id"}
                           variant={"outlined"}
                           data-testid={"ship-truck-id"}
                           onChange={event => palletShippingContextDispatcher({action: "setSealNumber", sealNumber: event.target.value})}
                           value={containerSealNumber}/>
            </FormControl>
        </div>
        <FormLabel>
            {t("components.pallet.shipping.pickupSlot")}
        </FormLabel>
        <PalletShippingInformationSelect shippingInformationType={ShippingInformationType.PickupSlot}
                                         loadData={loadPickupSlots} />
    </>;
};
