import { t } from "i18next";
import moment from "moment/moment";

export interface IPickupSlot {
    id: string;
    truckLicensePlate: string;
    trailerLicensePlate: string;
    carrierName: string;
    estimatedArrivalDate: moment.Moment;
}

export class PickupSlot {
    private readonly _id: string;
    private readonly _truckLicensePlate: string;
    private readonly _trailerLicensePlate: string;
    private readonly _carrierName: string;
    private readonly _estimatedArrivalDate: moment.Moment;

    public constructor(pickupSlot: IPickupSlot) {
        this._id = pickupSlot.id;
        this._truckLicensePlate = pickupSlot.truckLicensePlate;
        this._trailerLicensePlate = pickupSlot.trailerLicensePlate;
        this._carrierName = pickupSlot.carrierName;
        this._estimatedArrivalDate = pickupSlot.estimatedArrivalDate;
    }

    public get id() {
        return this._id;
    }

    public get truckLicensePlate() {
        return this._truckLicensePlate;
    }

    public get trailerLicensePlate() {
        return this._trailerLicensePlate;
    }

    public get carrierName() {
        return this._carrierName;
    }

    public get estimatedArrivalDate() {
        return this._estimatedArrivalDate;
    }

    public format() {
        const carrierName = this._carrierName === "" || this._carrierName === null
            ? ""
            : `${this._carrierName} - `;

        const licensePlates: string[] = [];

        if (this._truckLicensePlate !== "" && this._truckLicensePlate !== null) {
            licensePlates.push(this._truckLicensePlate);
        }

        if (this._trailerLicensePlate !== "" && this._trailerLicensePlate !== null) {
            licensePlates.push(`${t("model.pickupSlot.trailer")}: ${this._trailerLicensePlate}`);
        }

        const licensePlatesString = licensePlates.length === 0 ? "" : ` (${licensePlates.join(", ")})`;

        return `${carrierName}${moment(this._estimatedArrivalDate).format("Y-MM-DD")}${licensePlatesString}`;
    }
}
