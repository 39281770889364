import { Moment } from "moment";

export class PalletShippingInformationSelectItem {
    private readonly _id: string;
    private readonly _truckIdentifier: string;
    private readonly _pickingDate: Moment;
    private readonly _containerSealNumber: string;
    private readonly _formatted: string;

    constructor(id: string, pickingDate: Moment, truckIdentifier: string, containerSealNumber: string, formatted: string) {
        this._id = id;
        this._formatted = formatted;
        this._pickingDate = pickingDate;
        this._truckIdentifier = truckIdentifier;
        this._containerSealNumber = containerSealNumber;
    }

    public get id() {
        return this._id;
    }

    public get truckIdentifier() {
        return this._truckIdentifier;
    }

    public get pickingDate() {
        return this._pickingDate;
    }

    public get containerSealNumber() {
        return this._containerSealNumber;
    }

    public get formatted() {
        return this._formatted;
    }
}
