import { Guid } from "guid-typescript";
import { IRemotePrintingContext } from "./remote-printing-context";
import { RemoteDocumentJob } from "./remote-document-job";

interface IPrintingContextReducerInitializePayload {
    action: "push";
    documents: RemoteDocumentJob[];
}

interface IPrintingContextReducerSetPayload {
    action: "complete";
    documentId: Guid;
}

export type IPrintingContextReducerPayload = IPrintingContextReducerInitializePayload | IPrintingContextReducerSetPayload;

export const RemotePrintingContextReducer = (context: IRemotePrintingContext, message: IPrintingContextReducerPayload) => {
    switch (message.action) {
        case "push":
            if (message.documents.length === 0) {
                return context;
            }

            return {
                ...context,
                id: Guid.create(),
                queue: [...context.queue,
                    ...message.documents.filter(newJob =>
                        !context.queue.find(job => newJob.documentId.equals(job.documentId)))],
            };

        case "complete":
            return {
                ...context,
                id: Guid.create(),
                queue: context.queue.map(job =>
                    job.documentId.equals(message.documentId) ? job.complete() : job),
            };
    }
};
