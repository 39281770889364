import errorSound from "../sounds/error.mp3";
import areaSound from "../sounds/area.mp3";
import labelSound from "../sounds/label.mp3";
import { context } from "../../context/context";

export enum Sound {
    Error,
    Success,
    Back,
}

export const useSound = (sound: Sound) => {
    if (context.settings.muted) {
        return false;
    }

    switch (sound) {
        case Sound.Error:
            return (new Audio(errorSound)).play();
        case Sound.Success:
            return (new Audio(areaSound)).play();
        case Sound.Back:
            return (new Audio(labelSound)).play();
    }

    return true;
};
