import { IPickupSlot, PickupSlot } from "../model/pickup-slot";
import { httpGetList } from "../../common/network/fetch";
import { UrlBuilder } from "./url-builder";
import moment from "moment";

class PickupSlotsService {
    public async getPickupSlots(warehouse: string): Promise<PickupSlot[]> {
        return (await httpGetList<IPickupSlot>({
            url: new UrlBuilder()
                .setPickupSlots()
                .setWarehouse(warehouse)
                .setList()
                .setArrivalDate(moment())
                .build(),
        })).map(ps => new PickupSlot(ps));
    }
}

export const pickupSlotsService = new PickupSlotsService();
