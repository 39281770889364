import { IPalletShippingContext } from "./pallet-shipping-context";
import { IPalletShippingInformation } from "../../../model/pallet-shipping-information";
import moment from "moment";

interface IPalletShippingContextReducerInitializePayload {
    action: "initialize";
    isTruckIdentifierRequired?: boolean;
}

interface IPalletShippingContextReducerSetShippingInformationPayload {
    action: "setShippingInformation";
    shippingInformation: IPalletShippingInformation;
}

interface IPalletShippingContextReducerSetTruckIdPayload {
    action: "setTruckId";
    truckId: string;
}

interface IPalletShippingContextReducerSetPickingDatePayload {
    action: "setPickingDate";
    pickingDate: moment.Moment;
}

interface IPalletShippingContextReducerSetSealNumberPayload {
    action: "setSealNumber";
    sealNumber: string;
}

interface IPalletShippingContextReducerSetCommentPayload {
    action: "setComment";
    comment: string;
}

export type PalletShippingContextReducerPayload = IPalletShippingContextReducerInitializePayload
    | IPalletShippingContextReducerSetShippingInformationPayload
    | IPalletShippingContextReducerSetTruckIdPayload
    | IPalletShippingContextReducerSetPickingDatePayload
    | IPalletShippingContextReducerSetSealNumberPayload
    | IPalletShippingContextReducerSetCommentPayload;

export const PalletShippingContextReducer = (state: IPalletShippingContext, message: PalletShippingContextReducerPayload) => {
    switch (message.action) {
        case "initialize":
            return {
                isFormValid: message.isTruckIdentifierRequired !== undefined && !message.isTruckIdentifierRequired,
                isTruckIdentifierRequired: message.isTruckIdentifierRequired,
                shippingInformation: {
                    id: "",
                    truckIdentifier: "",
                    containerSealNumber: "",
                    pickingDate: moment(),
                },
            } as IPalletShippingContext;
        case "setShippingInformation":
            if (message.shippingInformation.containerSealNumber === "") {
                message.shippingInformation.containerSealNumber = state.shippingInformation.containerSealNumber;
            }
            return {
                ...state,
                shippingInformation: message.shippingInformation,
                isFormValid: true,
            };
        case "setTruckId":
            return {
                ...state,
                shippingInformation: {...state.shippingInformation, truckIdentifier: message.truckId},
                isFormValid: !state.isTruckIdentifierRequired || message.truckId !== "",
            };
        case "setPickingDate":
            return {
                ...state,
                shippingInformation: {...state.shippingInformation, pickingDate: message.pickingDate},
            };
        case "setSealNumber":
            return {
                ...state,
                shippingInformation: {...state.shippingInformation, containerSealNumber: message.sealNumber},
            };
        case "setComment":
            return {
                ...state,
                comment: message.comment,
            };
    }
};
