import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { loaderModel } from "./loader-model";
import { observer } from "mobx-react";

export const LoaderIndicatorWidget = observer(() => {
    return loaderModel.hasBackgroundAction ? <Button variant={"outlined"}
                   size={"large"}
                   style={{cursor: "default"}}>
        <CircularProgress size={22} />
    </Button> : <></>;
});
