import React, { Dispatch, useContext, useEffect, useReducer } from "react";
import { createContext, ReactNode } from "react";
import moment from "moment/moment";
import { IPalletShippingInformation } from "../../../model/pallet-shipping-information";
import { PalletShippingContextReducer, PalletShippingContextReducerPayload } from "./pallet-shipping-context-reducer";
import { useContextTypeSafe } from "../../../../common/hooks/use-context-type-safe";

interface IPalletShippingContextProviderProps {
    children: ReactNode;
}

export interface IPalletShippingContext {
    isFormValid: boolean;
    shippingInformation: IPalletShippingInformation;
    isTruckIdentifierRequired: boolean;
    comment: string;
}

const defaultContext: IPalletShippingContext = {
    isFormValid: false,
    isTruckIdentifierRequired: false,
    comment: "",
    shippingInformation: {
        id: "",
        truckIdentifier: "",
        containerSealNumber: "",
        pickingDate: moment(),
    },
};

const PalletShippingContext = createContext<IPalletShippingContext>(defaultContext);
const PalletShippingContextDispatcher = createContext<Dispatch<PalletShippingContextReducerPayload> | null>(null);

export const PalletShippingContextProvider = (props: IPalletShippingContextProviderProps) => {
    const [palletShippingContext, palletShippingContextDispatcher] = useReducer(PalletShippingContextReducer, defaultContext);

    useEffect(() => {
        palletShippingContextDispatcher({
            action: "initialize",
        });
    }, []);

    return <PalletShippingContext.Provider value={palletShippingContext}>
        <PalletShippingContextDispatcher.Provider value={palletShippingContextDispatcher}>
            {props.children}
        </PalletShippingContextDispatcher.Provider>
    </PalletShippingContext.Provider>;
};

export const usePalletShippingContext = () => {
  return useContext(PalletShippingContext);
};

export const usePalletShippingContextDispatcher = () => {
  return useContextTypeSafe(PalletShippingContextDispatcher);
};
