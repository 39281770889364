import * as React from "react";

import { useEffect, useState } from "react";
import { TestPagePrinter } from "./test-page-printer";
import { qzManager } from "../../qz/qz-manager";
import { context } from "../../../context/context";
import {
    Button,
    Card,
    FormControlLabel,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material";
import { InsertDriveFile, Print, PriorityHigh, Refresh } from "@mui/icons-material";
import { t } from "i18next";
import { SettingsFieldItem } from "./settings-field-item";
import { getPrinterLabelExtension, printerExtensionList } from "../../../constants/constants";
import { Permission } from "../../../context/permission";

import "./style.scss";

export const PrinterSettings = () => {
    const [printersList, setPrintersList] = useState<string[]>([]);
    const handleTestPagePrint = () => {
        (async () => {
            await (new TestPagePrinter()).print();
        })();
    };

    const handlePrintersListRefresh = () => {
        return (async () => {
            try {
                setPrintersList(await qzManager.loadAllPrinters());
            } catch (err) {
                setPrintersList([]);
            }
        })();
    };

    useEffect(() => {
        (async () => {
            await handlePrintersListRefresh();
        })();
    }, []);

    const handleIsDownloadChange = (value: string) => {
        (async () => {
            await context.updateIsDownload(`${value}`);
        })();
    };

    const handlePrinterPaperFormatChange = (value: boolean) => {
        (async () => {
            await context.updatePrinterPaperFormat(value ? "A4" : "");
        })();
    };

    const handlePrinterTypeChange = (value: string) => {
        (async () => {
            await context.updatePrinterType(value);
        })();
    };

    const handlePrinterItemClick = (printer: string) => {
        (async () => {
            await context.updatePrinterName(printer);
        })();
    };

    return <Card elevation={0} className={"settings-card"}>
        <div className={"settings-card__title-container"}>
            <Typography variant={"h2"} className={"settings-card__title"}>
                <Print />
                {t("settings.header.printer")}
            </Typography>
            <Button endIcon={<InsertDriveFile />} onClick={() => handleTestPagePrint()} size={"small"}>
                {t("settings.printer.printTestPage")}
            </Button>
        </div>
        <div className={"settings-card__contents"}>
            <SettingsFieldItem id={"delivery-method"} label={t("settings.printer.deliveryMethod")}>
                <RadioGroup value={`${context.settings.isDownload}`}
                            onChange={event => handleIsDownloadChange(event.target.value)}
                            className={"settings-card__radio-group"}>
                    <FormControlLabel value={`${false}`}
                                      control={<Radio />}
                                      label={t("settings.printer.deliveryMethodPrint")}
                                      data-testid="setting-print" />
                    <FormControlLabel value={`${true}`}
                                      control={<Radio />}
                                      label={t("settings.printer.deliveryMethodDownload")}
                                      data-testid="setting-download" />
                </RadioGroup>
            </SettingsFieldItem>
            <SettingsFieldItem id={"paper-format"} label={t("settings.printer.a4format")}>
                <Switch onChange={() => handlePrinterPaperFormatChange(!(context.settings.printerPaperFormat === "A4"))}
                        checked={context.settings.printerPaperFormat === "A4"}
                        data-testid="activeA4PaperFormat" />
            </SettingsFieldItem>
            <SettingsFieldItem id={"print-mode"} label={t("settings.printer.printMode")}>
                <Select id={"print-mode"}
                        value={context.settings.printerType}
                        variant={"outlined"}>
                    {printerExtensionList.map(printerExtension =>
                        <MenuItem value={printerExtension}
                                  key={printerExtension}
                                  onClick={() => handlePrinterTypeChange(printerExtension)}>
                            {getPrinterLabelExtension(printerExtension)}
                        </MenuItem>)}
                </Select>
            </SettingsFieldItem>
        </div>
        <div>
            <div className={"printer-list__header"}>
                <Typography variant={"h3"}>
                    {t("settings.printer.selectPrinter")}
                </Typography>
                <Tooltip title={t("settings.printer.refreshPrinterList")} placement={"right"}>
                    <IconButton onClick={() => handlePrintersListRefresh()}><Refresh /></IconButton>
                </Tooltip>
            </div>
            <List className={"printer-list__container"}>
                {printersList.map(printer => <ListItemButton key={printer}
                                                             disabled={!context.hasPermission(Permission.FrontWrite)}
                                                             onClick={() => handlePrinterItemClick(printer)}>
                    {context.settings.printerName === printer && <ListItemIcon>
                        <Print />
                    </ListItemIcon>}
                    <ListItemText>
                        {printer}
                    </ListItemText>
                </ListItemButton>)}
            </List>
            {printersList.length === 0 && <Typography component={"div"}
                                                      color={"textSecondary"}
                                                      className={"printer-list__warning"}>
                <PriorityHigh />
                <Typography>
                    {t("settings.printer.noPrintersAvailable")}
                </Typography>
            </Typography>}
        </div>
    </Card>;
};
