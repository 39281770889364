import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { t } from "i18next";
import { PalletShippingInformationManualInput } from "./pallet-shipping-information-manual-input";
import { PalletShippingInformationPickupSlotInput } from "./pallet-shipping-information-pickup-slot-input";

interface IPalletShippingInformationInputProps {
    isTruckIdentifierRequired: boolean;
}

export const PalletShippingInformationInput = (props: IPalletShippingInformationInputProps) => {
    const [useManualInput, setUseManualInput] = useState(true);

    return <>
        {useManualInput
            ? <PalletShippingInformationManualInput isTruckIdentifierRequired={props.isTruckIdentifierRequired} />
            : <PalletShippingInformationPickupSlotInput />}
        <FormControlLabel label={t("components.pallet.shipping.useManualInput")}
                          control={
            <Checkbox onChange={() => setUseManualInput(!useManualInput)} checked={useManualInput} />
        }/>
    </>;
};
