import React, { useState } from "react";
import { useEffectAsync } from "../../../../../common/hooks/use-effect-async";
import { CircularProgress, FormControl, FormLabel, MenuItem, Select, TextField } from "@mui/material";
import { t } from "i18next";
import { PalletShippingInformationSelectItem } from "./pallet-shipping-information-select-item";
import { usePalletShippingContext, usePalletShippingContextDispatcher } from "../pallet-shipping-context";
import moment from "moment";

export enum ShippingInformationType {
    PreviousPalletShipping = "previousPalletShipping",
    PickupSlot = "pickupSlots",
}

interface IPalletShippingInformationSelect {
    shippingInformationType: ShippingInformationType;
    loadData: () => Promise<PalletShippingInformationSelectItem[]>;
}

export const PalletShippingInformationSelect = (props: IPalletShippingInformationSelect) => {
    const [shippingInformationList, setShippingInformationList] = useState<PalletShippingInformationSelectItem[]>([]);
    const [shippingId, setShippingId] = useState("placeholder");
    const [loadingData, setLoadingData] = useState(true);

    const {
        comment,
        shippingInformation: {containerSealNumber},
    } = usePalletShippingContext();

    const palletShippingContextDispatcher = usePalletShippingContextDispatcher();

    useEffectAsync(async () => {
        palletShippingContextDispatcher({action: "initialize"});
        setShippingInformationList(await props.loadData());
        setLoadingData(false);
    }, []);

    const handleOnSelect = (id: string) => {
        setShippingId(id);
        const shippingInformation = shippingInformationList?.find(si => si.id === id);

        if (shippingInformation) {
            palletShippingContextDispatcher({
                action: "setShippingInformation",
                shippingInformation: {
                    id: props.shippingInformationType === ShippingInformationType.PickupSlot
                        ? shippingInformation.id
                        : "",
                    containerSealNumber: shippingInformation.containerSealNumber ?? containerSealNumber,
                    truckIdentifier: shippingInformation.truckIdentifier,
                    pickingDate: moment(shippingInformation.pickingDate),
                },
            });
        }
    };

    return <>
        <div className={"pallet-shipping-form__select"}>
            <Select id="shipping-information-select"
                    data-testid={"ship-pallet-shipping-information-select"}
                    value={shippingId}
                    disabled={shippingInformationList.length === 0}>
                {loadingData &&
                    <MenuItem value={"placeholder"} key={"shipping-information-loading"}>
                        <CircularProgress size={20}/>
                        {t(`components.pallet.shipping.${props.shippingInformationType}.informationLoading`)}
                    </MenuItem>}
                {!loadingData && shippingInformationList.length === 0 &&
                    <MenuItem value={"placeholder"} key={"shipping-information-loading"}>
                        {t(`components.pallet.shipping.${props.shippingInformationType}.informationNotFound`)}
                    </MenuItem>}
                {shippingInformationList.map(si =>
                    <MenuItem data-testid={`shipping-information-select-item-${si.id}`}
                              onClick={() => handleOnSelect(si.id)}
                              key={si.id}
                              value={si.id}>
                        {si.formatted}
                    </MenuItem>)
                }
            </Select>
        </div>
        <div className={"pallet-shipping-form__comment"}>
            <FormControl variant={"outlined"} fullWidth>
                <FormLabel htmlFor={"closing-comment"}>
                    {t("components.pallet.shipping.comment")}
                </FormLabel>
                <TextField id={"shipping-comment"}
                           variant={"outlined"}
                           data-testid={"shipping-comment"}
                           multiline
                           rows={2}
                           maxRows={4}
                           inputProps={{maxLength: 255}}
                           onChange={event => palletShippingContextDispatcher({
                               action: "setComment",
                               comment: event.target.value,
                           })}
                           value={comment}/>
            </FormControl>
        </div>
    </>;
};
