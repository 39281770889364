import { Dictionary, flatMap, sortBy, sumBy, orderBy, groupBy, mapValues } from "lodash";
import { domain, label } from "../../common/dto/dto-annotation";
import { label15, label255, label50 } from "../../domain/label";
import { DeliveryOrderDetail, IDeliveryOrderDetail } from "./delivery-order-detail";
import { DeliveryOrderStatusType } from "../../constants/constants";
import { Parcel } from "./parcel";
import { identifier } from "../../domain/identifier";
import { action, computed, observable } from "mobx";
import { quantity } from "../../domain/quantity";
import { ParcelDetail } from "./parcel-detail";
import { DetailContentType, ParcelStatusType } from "../constants/constants";
import { t } from "i18next";
import { Stockout, StockoutStatus } from "./stockout";
import { DeliveryOrderCancellationRequestStatus } from "./delivery-order-cancellation-request-status";
import { IShippingAddress, ShippingAddress } from "./shipping-address";
import { DangerousGoods } from "../../constants/constants";
import { lithiumUnCodeList } from "../../constants/constants";

export interface IDeliveryOrder {
    /** Preparation order number */
    id: number;

    /** Command number */
    orderId: number;

    /** Shipping address */
    shippingAddress: IShippingAddress;

    /** Carrier Key */
    carrier: string;

    /** Delivery order status */
    status: DeliveryOrderStatusType;

    /** Delivery order type */
    type: DetailContentType;

    /** Delivery order cancellation request status */
    cancellationRequestStatus: DeliveryOrderCancellationRequestStatus;

    /** Ordered items. */
    details: IDeliveryOrderDetail[];
}

export class DeliveryOrder {
    constructor(operationCode: string, batchId: number, deliveryOrder: IDeliveryOrder, parcels: Parcel[] = []) {
        this._id = deliveryOrder.id;
        this._orderId = deliveryOrder.orderId;
        this._operationCode = operationCode;
        this._batchId = batchId;
        this._parcels = parcels;
        this._stockouts = deliveryOrder.details.filter(detail => detail.quantityStockout > 0)
            .map(detail => this.createProcessedStockout(detail.productId, detail.quantityStockout));
        this._details = sortBy(deliveryOrder.details, "supplierReference")
            .map(detail => new DeliveryOrderDetail(detail));
        this._shippingAddress = new ShippingAddress(deliveryOrder.shippingAddress);
        this._carrier = deliveryOrder.carrier;
        this._status = deliveryOrder.status;
        this._contentsSortingKey = this.details.map(detail => detail.supplierReference).join("-");
        this._isSingleRef = deliveryOrder.type === "SingleReference";
        this._isMonoRef = deliveryOrder.type === "MonoReference";
        this._isMultiRef = deliveryOrder.type === "MultiReference";
        this._type = deliveryOrder.type;
        this._detailSortingKey = (this.isMonoRef ? 0 : 1) + (this.isSingleRef ? 0 : 1) + `0000${deliveryOrder.details.length}_`.slice(-5)
            + this.contentsSortingKey
            + `-0000${sumBy(deliveryOrder.details, d => d.quantity)}_`.slice(-5);
        this._cancellationRequestStatus = deliveryOrder.cancellationRequestStatus;
        this._isContainingProductSet = this._details.some(detail => detail.isContainingProductSet);
        this._dangerousGoods = this.aggregateDangerouseGoodsFromDetails(deliveryOrder.details);
    }

    /** Preparation order number */
    private readonly _id: number;

    /** Command number */
    private readonly _orderId: number;

    /** Operation code */
    private readonly _operationCode: string;

    /** Batch Id */
    private readonly _batchId: number;

    /** Parcels list */
    @observable
    private readonly _parcels: Parcel[];

    /** Stockouts list */
    @observable
    private readonly _stockouts: Stockout[];

    /** Order details list */
    private _details: DeliveryOrderDetail[];

    /** Shipping address */
    private readonly _shippingAddress: ShippingAddress;

    /** Carrier Key (Transporteur) */
    private readonly _carrier: string;

    /** Order status */
    private readonly _status: DeliveryOrderStatusType;

    /** Contents sorting key */
    private readonly _contentsSortingKey: string;

    /** Order content type with contents sorting key */
    private readonly _detailSortingKey: string;

    /** Mono ref order flag */
    private readonly _isMonoRef: boolean;

    /** Single ref order flag */
    private readonly _isSingleRef: boolean;

    /** Multi ref order flag */
    private readonly _isMultiRef: boolean;

    /** Order type */
    private readonly _type: DetailContentType;

    /** Delivery order cancellation request status */
    private readonly _cancellationRequestStatus: DeliveryOrderCancellationRequestStatus;

    private _isContainingProductSet: boolean;

    /** Included dangerous goods type */
    private readonly _dangerousGoods: DangerousGoods;

    @label("model.deliveryOrder.deliveryOrderId")
    @domain(identifier)
    public get id(): number {
        return this._id;
    }

    @label("model.deliveryOrder.deliveryOrderId")
    @domain(identifier)
    public get deliveryOrderId(): number {
        return this.id;
    }

    @label("model.deliveryOrder.orderId")
    @domain(identifier)
    public get orderId(): number {
        return this._orderId;
    }

    @domain(label50)
    public get operationCode(): string {
        return this._operationCode;
    }

    @domain(identifier)
    public get batchId(): number {
        return this._batchId;
    }

    @domain(label15)
    public get zipCode(): string {
        return this._shippingAddress.zipCode;
    }

    @domain(label50)
    public get city(): string {
        return this._shippingAddress.city;
    }

    @domain(label255)
    public get country(): string {
        return this._shippingAddress.country;
    }

    @label("model.deliveryOrder.carrier")
    @domain(label50)
    public get carrier(): string {
        return this._carrier;
    }

    @domain(label50)
    public get status(): DeliveryOrderStatusType {
        return this._status;
    }

    @label("model.deliveryOrder.parcelCount")
    @domain(quantity)
    public get parcelCount(): number {
        return this.parcels.length;
    }

    @label("model.deliveryOrder.detailSortingKey")
    public get detailSortingKey(): string {
        return this._detailSortingKey;
    }

    @label("model.deliveryOrder.contentsSortingKey")
    public get contentsSortingKey(): string {
        return this._contentsSortingKey;
    }

    public get isMonoRef(): boolean {
        return this._isMonoRef;
    }

    public get hasMonoRef(): boolean {
        return this._details.some(d => d.detailType === "MonoReference");
    }

    public get isSingleRef(): boolean {
        return this._isSingleRef;
    }

    @computed
    public get isMultiRef() {
        return this._isMultiRef;
    }

    public get hasMultiRef(): boolean {
        return this._details.some(d => d.detailType === "MultiReference");
    }

    public get cancellationRequestStatus(): DeliveryOrderCancellationRequestStatus {
        return this._cancellationRequestStatus;
    }

    @computed
    public get isPendingCancellation() {
        return this.cancellationRequestStatus === DeliveryOrderCancellationRequestStatus.Pending;
    }

    @computed
    public get isPendingCancellationOrCancelled() {
        return this.cancellationRequestStatus === DeliveryOrderCancellationRequestStatus.Pending ||
            this.cancellationRequestStatus === DeliveryOrderCancellationRequestStatus.Confirmed;
    }

    @computed
    public get type(): DetailContentType {
        return this._type;
    }

    @computed
    public get stockouts(): Stockout[] {
        return this._stockouts;
    }

    @computed
    public get parcelIds(): number[] {
        return this.parcels.map(p => p.parcelId);
    }

    @label("model.deliveryOrder.address")
    @domain(label255)
    public get address() {
        return this.shippingAddress.shortAddress;
    }

    public get details(): DeliveryOrderDetail[] {
        return this._details;
    }

    @computed
    public get parcels() {
        return this._parcels.filter(p => p.status !== "Cancelled");
    }

    @computed
    public get cancelledParcels(): Parcel[] {
        return this._parcels.filter(p => p.status === "Cancelled");
    }

    @computed
    public get createdParcels(): Parcel[] {
        return this._parcels.filter(p => p.id === undefined);
    }

    @computed
    public get updatedParcels(): Parcel[] {
        return this._parcels.filter(p => !p.isParcelDetailsSaved);
    }

    get shippingAddress(): ShippingAddress {
        return this._shippingAddress;
    }

    @computed
    public get requestedStockouts() {
        return this._stockouts.filter(stockout => stockout.status === StockoutStatus.Requested);
    }

    public get isContainingProductSet(): boolean {
        return this._isContainingProductSet;
    }

    public get dangerousGoods() {
        return this._dangerousGoods;
    }

    @action
    public sortDetailsBy(subProperty: keyof DeliveryOrderDetail, sortOrder: "asc" | "desc") {
        this._details = orderBy(this._details, subProperty, sortOrder);
    }

    @action
    public createParcel(parcelDetails: ParcelDetail[]): Parcel {
        if (this.status === "Shipped") {
            throw new Error(t("error.deliveryOrder.modifyShippedOrderViolation", {
                deliveryOrder: this.deliveryOrderId,
            }));
        }

        const parcel = new Parcel({
                batchId: this.batchId,
                operationCode: this.operationCode,
                deliveryOrderId: this.id,
                details: parcelDetails,
                weight: parcelDetails
                    .map(d => d.weight * d.quantity)
                    .reduce((previousValue, currentValue) => previousValue + currentValue, 0),
                orderId: this.orderId,
                status: "New",
                zipCode: this.shippingAddress.zipCode,
                city: this.shippingAddress.city,
                carrier: this.carrier,
                country: this.shippingAddress.country,
                isMonoRef: this.isMonoRef,
                isSingleRef: this.isSingleRef,
                orderCancellationRequestStatus: this.cancellationRequestStatus,
        });

        parcelDetails.forEach(parcelDetail => {
            const orderDetailProduct = this.getDetailByProductId(parcelDetail.productId);
            this.updateOrderDetailQuantity(
                orderDetailProduct,
                parcel.status,
                parcelDetail.quantity,
            );
        });

        this._parcels.push(parcel);
        this.validateParcelQuantities();

        return parcel;
    }

    @action
    public cancelParcel(parcelId: number) {
        const parcelToDelete = this.parcels.find(p => p.id === parcelId);
        if (parcelToDelete === undefined) {
            throw new Error(t("error.parcel.notFoundViolation", {
                parcel: parcelId,
                deliveryOrder: this.deliveryOrderId,
            }));
        }
        if (parcelToDelete.status === "Shipped") {
            throw new Error(t("error.parcel.modifyShippedParcelViolation", {
                parcel: parcelToDelete.parcelId,
                deliveryOrder: this.deliveryOrderId,
            }));
        }

        parcelToDelete.details.forEach(parcelDetail => {
            const orderDetailProduct = this.getDetailByProductId(parcelDetail.productId);
            this.updateOrderDetailQuantity(
                orderDetailProduct,
                parcelToDelete.status,
                -parcelDetail.quantity,
            );
        });

        parcelToDelete.cancel();
    }

    @action
    public stockoutProduct(productId: number, stockoutQuantity: number) {
        const orderDetailProduct = this.getDetailByProductId(productId);

        if (orderDetailProduct.quantityAvailable < stockoutQuantity) {
            throw new Error(t("error.parcel.invalidQuantitiesSupplied", {
                deliveryOrder: this.deliveryOrderId,
                productName: orderDetailProduct.labelReference,
            }));
        }

        this._stockouts.push(this.requestProductStockout(productId, stockoutQuantity));
        orderDetailProduct.setStockoutQuantity(sumBy(this._stockouts.filter(s => s.productId === productId), "quantity"));
    }

    private updateOrderDetailQuantity(orderDetail: DeliveryOrderDetail, parcelStatus: ParcelStatusType, quantityDifference: number) {
        switch (parcelStatus) {
            case "New":
                orderDetail.quantityParcelled += quantityDifference;
                break;
            case "Labeled":
                orderDetail.quantityLabeled += quantityDifference;
                break;
        }
    }

    private validateParcelQuantities() {
        const availableQuantities = this.detailsQuantityMap;
        const parcelledQuantities = this.parcelledDetailsQuantityMap;

        this.details.forEach(product => {
            if (parcelledQuantities[product.productId] !== undefined && parcelledQuantities[product.productId] > availableQuantities[product.productId]) {
                throw new Error(t("error.parcel.invalidQuantitiesSupplied", {
                    deliveryOrder: this.deliveryOrderId,
                    productName: product.labelReference,
                }));
            }
        });
    }

    private get detailsQuantityMap(): Dictionary<number> {
        return mapValues(groupBy(this.details, detail => detail.productId),
                product => sumBy(product, "quantity"));
    }

    private get parcelledDetailsQuantityMap(): Dictionary<number> {
        return mapValues(groupBy(flatMap(this.parcels, parcel => parcel.details),
                detail => detail.productId),
                product => sumBy(product, "quantity"));
    }

    private requestProductStockout(productId: number, stockoutQuantity: number) {
        return this.createStockout(productId, stockoutQuantity, StockoutStatus.Requested);
    }

    private createProcessedStockout(productId: number, stockoutQuantity: number) {
        return this.createStockout(productId, stockoutQuantity, StockoutStatus.Processed);
    }

    private createStockout(productId: number, stockoutQuantity: number, stockoutStatus: StockoutStatus) {
        return new Stockout({
            operationCode: this.operationCode,
            batchId: this.batchId,
            deliveryOrderId: this.deliveryOrderId,
            productId,
            quantity: stockoutQuantity,
            status: stockoutStatus,
        });
    }

    private aggregateDangerouseGoodsFromDetails(details: IDeliveryOrderDetail[]) {
        const unCodes = details.filter(d => d.unCode !== null)
            .flatMap(d => d.unCode);

        let lithium: DangerousGoods | undefined;
        let liquidQuantity: DangerousGoods | undefined;

        if (unCodes.some(unCode => lithiumUnCodeList.includes(unCode))) {
            lithium = DangerousGoods.Lithium;
        }

        if (unCodes.filter(unCode => !lithiumUnCodeList.includes(unCode)).length !== 0) {
            liquidQuantity = DangerousGoods.LiquidQuantity;
        }

        if (lithium !== undefined && liquidQuantity !== undefined) {
            return DangerousGoods.Both;
        } else {
            return lithium ?? liquidQuantity ?? DangerousGoods.None;
        }
    }

    public getDetailByProductId(productId: number): DeliveryOrderDetail {
        const detail = this._details.find(d => d.productId === productId);
        if (detail === undefined) {
            throw new Error(t("error.deliveryOrder.productNotFoundInOrder", {
                deliveryOrder: this.deliveryOrderId,
                productId,
            }));
        }

        return detail;
    }
}
